<template>
    <div class="article-list-box">
        <div class="article-list-box-two">
            <div class="article-list  wow slideInUp" style="position: relative;">
                <ul>
                    <li v-for="item in article_list">
                        <div class="article-img-box">
                            <a :href="'/article/' + item.article_id" target="_blank">
                              <img :src="`${$imgUrl}${item.img_src}`" >
                            </a>
                        </div>

                        <div class="article-info-box">
                            <div class="article-info-title"><a :href="'/article/' + item.article_id" target="_blank">{{ item.title }}</a></div>
                            <div class="article-info-time"><p>发布日期：{{ item.article_time }}</p></div>
                            <div class="article-info-content text-overflow-two" v-html="item.content"></div>
                        </div>
                    </li>
                </ul>

                <div v-if="!article_list.length" style="font-size: 20px;color: #C4C4C4;"><span>没有数据</span></div>

                <div class="more-article-box">
                    <router-link :to="moreArticleLink===16? '/life':'/skills'"><span class="more-article-btn">
                        更多相关文章
                    </span></router-link>
                </div>
            </div>
<!--            <el-button @click="getCheckedNodes">通过 node 获取</el-button>-->
<!--            <el-button @click="getCheckedKeys">通过 key 获取</el-button>-->

            <article-nav :article_class="article_class" :red_class="red_class" @getRedClass="(data)=>this.red_class = data">
                <slot>
                    <div class="nav-link-list">
                        <el-tree ref="tree" :data="NavData" :props="defaultProps" @node-click="handleNodeClick" node-key="value" :highlight-current="true" :default-expanded-keys="[17,]"
                        ></el-tree>
                    </div>
                </slot>
            </article-nav>


        </div>
    </div>

</template>

<script>
import ArticleNav from "../public/ArticleNav";
import {ElMessage} from "element-plus";
import {WOW} from 'wowjs'
export default {
    name: "ArticleList",
    props: ['article_class', ],
    components: {
        ArticleNav,
    },
    mounted(){
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: true
        });
        wow.init();
    },
    data() {
        return {
            red_class: '',
            article_list: [],

            NavData: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            moreArticleLink: '',

            linshiList: [],
        }


    },
    created() {
      let that = this;

        that.axios.get('/all_type')
            .then(function (response) {
                that.NavData = response.data.data_list;
                that.red_class = that.NavData[1].children[0].value;
                that.moreArticleLink = that.NavData[1].value;

                that.NavData.forEach(function (value, index, arr) {
                    arr[index].label = '\xa0\xa0\xa0\xa0' + value.label + `(${value.article_num})`;
                })
                that.$nextTick(function () {
                    that.$refs.tree.setCurrentKey(1,)
                })

            })
            .catch(function (error) {
                ElMessage({
                    showClose: true,
                    message: '文章列表获取失败，请刷新',
                    type: 'error'
                })
            })

    },

    methods: {
        // getCheckedNodes() {
        //     this.$refs.tree.setCurrentKey(1)
        //     console.log(this.$refs.tree.getCurrentKey());
        // },
        // getCheckedKeys() {
        //     console.log(this.$refs.tree.getCurrentNode());
        // },




        getDataList: function (type_id) {
          let that = this;
            that.axios.get('/article_list?article_type=' + type_id).
            then(function (response) {
                that.article_list = [];
                that.linshiList = [];
                response.data.data_list.forEach((item, i) => {
                    that.article_list.push(item);
                });

            }).catch(function (error) {
                ElMessage({
                    showClose: true,
                    message: '文章列表获取失败，请刷新',
                    type: 'error'
                })
            })
        },


        handleNodeClick: function (data) {
            if (data.children) {
                return false;
            }
            this.moreArticleLink = data.article_class;
            this.red_class = data.value;
        },

    },


    watch: {
        red_class: function () {
            this.getDataList(this.red_class);
        },
    },


}
</script>

<!--外层的盒子-->
<style>
.article-list-box {
    width: 100%;
    background-color: #ffffff;
    color: #666666;;
}

.article-list-box-two{
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 35px 0;
    display: flex;
}
.article-list{
    /*max-width: 100%;*/
    flex-basis: 75%;
    flex-grow: 1;
    /*overflow:scroll;*/
}

</style>

<!--文章列表-->
<style>
.article-list li{
    margin-bottom: 20px;
    padding-right: 10px;
    display: table;
}
.article-list li:hover .article-info-title a{
    color: red;
}
.article-img-box{
    width:355px;
    /*height: 100%;*/
    padding-right: 30px;
    vertical-align: top;
}
.article-img-box img{
    width: 100%;
    /*height: 100%;*/
}
.article-img-box a{
    display: block;
}

.article-info-box{
    display: table-cell;
    vertical-align: top;
}

.article-info-time, .article-info-content{
    padding-top: 20px;
}


.article-info-title a{
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
}
.article-info-time{
    color: #999999;
    font-size: 12px;
}
.article-info-content{
    color: #666666;
    font-size: 14px;
    max-height: 55px;
    -webkit-line-clamp: 3;
}

.article-info-title{
    padding-top: 50px;
}




@media screen and (min-width: 991px) and (max-width: 9999px) {
    .article-info-box{
        padding: 0 50px 0 25px;
    }

    .article-nav-select{
        display: none;
    }

    .list li{
        min-height: 250px!important;
    }


}


@media screen and (min-width: 501px) and (max-width: 990px) {
    .article-img-box{
        display: none!important;
    }
    .article-list-box-two{
        justify-content: center;
    }
    .article-list{
        /*flex-grow: 0.6;*/
    }
}
@media screen and (min-width: 0px) and (max-width: 500px) {
    .article-img-box{
        width: 100%;
        padding: 0;
    }
    .article-list li{
        display: block;
        padding: 0;
    }
    .article-info-title{
        padding-top: 20px;
    }

    .article-list li{
        padding-top: 25px;
    }
}

.more-article-box{
    text-align: center;
    width: 100%;
    padding-top: 35px;
}

.more-article-btn{
    color: rgb(231, 76, 60);
    border: 1px solid rgb(235, 235, 235);
    font-size: 14px;

    max-width: 100%;
    width: 200px;
    height: 50px;
    line-height: 50px;
    display: block;
    text-align: center;
    margin: auto;

}
.more-article-btn:hover{
    cursor: pointer;
    background-color: rgb(231, 76, 60);
    border-color: rgb(231, 76, 60);;
    color: rgb(255, 255, 255);

}


</style>


