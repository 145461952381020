<template>

    <div class="sub-video-box">
        <div class="video-change" style="" @click="one_index++;"><span class="el-icon-arrow-left"></span></div>
        <div class="video-list-box" style="" ref="scrollBox" @scroll="scrollChange">
            <div v-for="(item, index) in videoList"
                 class="video-info-box" :ref="'change_vide' + index" :key="'video' + index"

            >
                <div class="video-img-box" @click="openVideo(index)">

                    <div class="video-open-btn"  style="position: absolute;z-index: 999;top: 42%;left: 48%;">
                        <el-button type="info" @click="openVideo(index)" class="iconfont" circle style="font-size: 20px">&#xe602;</el-button>
                    </div>



                    <video
                        class="rotation-video"
                        :poster="item.img_src"
                        :ref="'change_video' + index"
                        muted loop
                        @mouseover="videoPlay(index)"
                        @mouseout="videoPaused(index, item)"
                    >

                        <source type="video/webm" :src="item.video_src">
                    </video>
                </div>
                <div class="video-text-info">
                    <div class="video-text-box">
                        <span class="video-title text-overflow">{{ item.title }}</span>
                        <span class="video-public-time">{{ item.public_time }}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="video-change" @click="one_index--"><span class="el-icon-arrow-right"></span></div>


    </div>
</template>

<script>
export default {
    name: "HomeCarousel",
    data() {
        return {
            videoList: [

            ],
            one_index: 0,
            transform_value: 0,
            screenWidth: document.body.clientWidth,

            dataList2: [],

            isPlay: true,  //手机端时候，  去除 鼠标移动移出的事件
            isAnima: true,
            // openVideo: false, //
            dataList:'',
            a: '',
            b: '',
            c: '',
        }
    },


    created() {
      this.initDataList()
      this.dataList2 = this.dataList.concat(this.dataList)
      if (this.screenWidth >= 901 || this.screenWidth <= 600) {
        this.videoList = this.dataList2
      }else {
        this.videoList = this.dataList
      }

    },
    methods: {
        initDataList() {
          this.dataList =  [
            {
              // img_src: 'http://img.mrzhao520.cn/home/0667ead2a56becfc7bcb321122451118.webm',
              img_src: `${this.$imgUrl}/home/1637133908.jpg`,
              title: '你认为幸福的，就去珍惜',
              public_time: '2020-10-30',
              // video_src: 'http://5dbf968054c17.t74.qifeiye.com/qfy-content/uploads/2019/10/0667ead2a56becfc7bcb321122451118.webm',
              video_src: `${this.$videoUrl}/home/0667ead2a56becfc7bcb321122451118.webm`,
              openVideo: false,
            },
            {
              // img_src: 'http://img.mrzhao520.cn/home/9d47f60ce39e8554d0ef40ba66875b7b.webp',
              img_src: `${this.$imgUrl}/home/1637133773.jpg`,
              title: '聆听你，感悟你，写意你',
              public_time: '2020-10-30',
              // video_src: 'http://5dbf968054c17.t74.qifeiye.com/qfy-content/uploads/2019/10/9d47f60ce39e8554d0ef40ba66875b7b.webm',
              video_src: `${this.$videoUrl}/home/9d47f60ce39e8554d0ef40ba66875b7b.webm`,
              openVideo: false,
            },
            {
              // img_src: 'http://img.mrzhao520.cn/home/b02192a9e1f1e10f55e2940812101b6c.webp',
              img_src: `${this.$imgUrl}/home/1637134093.jpg`,
              title: '几度梦回诗无意，落日余晖念成痴',
              public_time: '2020-10-30',
              // video_src: 'http://5dbf968054c17.t74.qifeiye.com/qfy-content/uploads/2019/10/b02192a9e1f1e10f55e2940812101b6c.webm',
              video_src: `${this.$videoUrl}/home/b02192a9e1f1e10f55e2940812101b6c.webm`,
              openVideo: false,
            },
            {
              // img_src: 'http://img.mrzhao520.cn/home/b1b3a31db0bad745e11ed3e73e9ba65c.webp',
              img_src: `${this.$imgUrl}/home/1637134463.jpg`,
              title: '人生是一场没有目的地的旅行',
              public_time: '2020-10-30',
              // video_src: 'http://5dbf968054c17.t74.qifeiye.com/qfy-content/uploads/2019/10/b1b3a31db0bad745e11ed3e73e9ba65c.webm',
              video_src: `${this.$videoUrl}/home/b1b3a31db0bad745e11ed3e73e9ba65c.webm`,
              openVideo: false,
            },
            // {
            //     img_src: 'http://img.mrzhao520.cn/home/img/video5.jpg',
            //     title: '开局一条鲲',
            //     public_time: '2020-10-30',
            //     video_src: 'http://img.mrzhao520.cn/home/video/gfd9fkd.mp4',
            //     openVideo: false,
            // }
          ]
        },
        videoPlay: function (index) {
          // this.$refs['change_video' + index].style.background = ''
          if (!this.isPlay) {
                return false
            }
          this.$refs['change_video' + index].play()
        },
        videoPaused: function (index, item) {
          // this.$refs['change_video' + index].style.background = "`transparent url(${item.img_src}) 50% 50% no-repeat;`"
          if (!this.isPlay) {
                return false
            }
          this.$refs['change_video' + index].load()
        },


        // 监听滚动条的状态
        scrollChange: function () {
            var one_video_len = this.$refs['scrollBox'].scrollWidth / this.videoList.length;
            var multiple;
            var scroll_len;

            let scrollLeft = Number(this.$refs['scrollBox'].scrollLeft).toFixed(0);
            let scrollWidth = Number(this.$refs['scrollBox'].scrollWidth).toFixed(0);
            let clientWidth = Number(this.$refs['scrollBox'].clientWidth).toFixed(0);


            // this.a = scrollLeft;
            // this.b = scrollWidth;
            // this.c = clientWidth;

            if (this.$refs['scrollBox'].scrollLeft === 0) {
                this.initialScroll() // 初始化滚动条
            }else if (scrollLeft > scrollWidth - clientWidth - 10) {
                this.initialScroll(2);
            }
        },


        // 初始化滚动条
        initialScroll: function (num = 0) {
            //  num:   0：滚动条移动到开头。      2：滚动条移动到结尾
            var one_video_len = this.$refs['scrollBox'].scrollWidth / this.videoList.length;
            this.$refs.scrollBox.style.scrollBehavior = 'auto'
            this.$refs['scrollBox'].scrollLeft = Number(one_video_len * ((this.videoList.length / 2) - num)).toFixed(0)
            this.one_index = (this.videoList.length / 2) - num;
            this.$refs.scrollBox.style.scrollBehavior = 'smooth'
        },

        // 点击播放，弹出大视频窗口
        openVideo: function (videoIndex) {
            this.$alert('<video controls="controls" class="alert-video" src="' + this.videoList[videoIndex].video_src + '" width="80%" height="80%" ' +
                'style="max-height: 800px;max-width: 1250px;" autoplay preload></video>', '', {
                dangerouslyUseHTMLString: true,
                showClose: false,
                closeOnClickModal: true,
                closeOnPressEscape: true,
            });
        }


    },

    watch:{
        one_index:{
            handler: function(val) {
                var one_video_len = this.$refs['scrollBox'].scrollWidth / this.videoList.length;  //   总长度 / 数量 =  单个长度
                if (val === 0){
                    this.initialScroll(-1);
                    this.one_index = this.videoList.length / 2;
                    return
                }else if(val === this.videoList.length - 2){
                    this.initialScroll(3);
                    this.one_index = 3;
                    return
                }else if(val === -1){
                    this.initialScroll();
                    this.one_index = 4;
                    return
                }
                var scroll_len = Number(one_video_len * this.one_index).toFixed(0); // 需要滚动到的位置
                this.$refs['scrollBox'].scrollLeft = scroll_len
            },
            immediate: false
        },



        screenWidth: function (val) {
            if (val <= 9999 && val >= 991){
                if (this.videoList !== this.dataList2) {
                    this.videoList = this.dataList2;
                }
                this.initialScroll();
                this.isPlay = true;
            } else if (val <= 990 && val >= 601){
                if (this.videoList !== this.dataList) {
                    this.videoList = this.dataList;
                }
                this.isPlay = true;
            } else if (val <= 600 && val >= 0){
                if (this.videoList !== this.dataList2) {
                    this.videoList = this.dataList2;
                }
                // this.initialScroll();
                this.isPlay = false;
            }

        },

    },

    mounted() {
        let self = this;
        const that = this
        if (document.body.clientWidth <= 600) {
            that.isPlay = false
        }

        window.addEventListener("resize", function() {
            return (() => {
                window.screenWidth= document.body.clientWidth;
                that.screenWidth= window.screenWidth;
            })();
        });
    },
}
</script>

<!--三个视频-->
<style>
.video-box{
    background-color: #fafafa;
}
.rotation-video{
  height: 100%;
  width: 100%;
  vertical-align: bottom;
  /*object-fit: cover;*/
  /*object-fit:fill;*/
  /*background: transparent url("http://img.mrzhao520.cn/home/1637133908.jpg") 50% 50% no-repeat;*/
  /*background-image: url("http://img.mrzhao520.cn/home/1637133908.jpg");*/
  /*background-position: center;*/
  /*background-repeat: no-repeat;*/



  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
}

.sub-video-box {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.video-list-box{
    /*border: 1px solid red;*/
    box-sizing: border-box;
    flex: 1;
    overflow-x: scroll;
    position: relative;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

}

.video-text-info{
    background-color: white;
    max-height: 100px;
}

.video-info-box{
    /*border: 1px solid black;*/
    box-sizing: border-box;
    height: 100%;
}
.video-info-box:hover .video-title{
    color: #e74c3c;
}




.video-title{
    display: block;
    padding: 0 8%;
    max-width: 84%;
}
.video-public-time{
    color: #e74c3c;
    font-size: 13px;
    padding: 8px 0;
    display: block;
    text-align: center;
}

::-webkit-scrollbar{display:none;}
.video-img-box{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 70%;
    position: relative;
}
.video-img-box:hover{
    cursor: pointer;
}


.video-change{
    font-size: 30px;
}
.video-change:hover{
    cursor: pointer;
}

.video-change{
    display: none;
}


@media screen and (min-width: 601px) and (max-width: 9999px) {
    .video-text-info{
        padding-top: 15px;
        height: 60px;
    }

    .video-title{
        white-space: nowrap;
        font-size: 16px;
        text-align: center;
    }
    .video-list-box{
        /*scroll-margin-left: 0;*/
      min-height: 370px;
    }


    .el-message-box{
        max-width: 80%;
        min-width: 60%;
    }

}


@media screen and (min-width: 991px) and (max-width: 9999px) {
    .video-info-box{
        flex-basis: 31%;
        flex-shrink: 0;
        margin-right: 3.5%;
    }
    .video-list-box{
        /*min-height: 500px;*/
      height: 50vh;
      scroll-behavior:smooth;
    }


    .video-change{
        display: block;
    }

}


@media screen and (min-width: 601px) and (max-width: 990px) {

    .video-list-box{
        flex-direction: column;
    }
    .video-img-box{
        /*max-width: 100%;*/
        /*max-height: 100%;*/
        /*height: 450px;*/
        /*padding-bottom: 50%;*/
        /*max-height: 440px;*/
    }
  .rotation-video{
    max-height: 333px;
  }


}


@media screen and (min-width: 0px) and (max-width: 600px) {
    .sub-video-box{
        padding: 20px 0;
    }

    .video-list-box{
        height: 210px;
    }
    .video-text-info{
        height: 30%;
    }
    .video-info-box{
        flex-basis: 45%;
        flex-shrink: 0;
        margin-right: 5%;
        background-color: white;
    }
    .video-title{
        font-size: 14px;
        white-space: normal;
        text-align: left;
    }
    .video-public-time{
        display: none;
    }

    .video-open-btn{
        display: none;
    }

    .video-img-box{
        padding: 0 8%;
        justify-content: flex-start;
    }
    /*.video-open-btn{*/
    /*    left: 46px!important;*/
    /*}*/
    .video-open-btn button{
        font-size: 15px!important;
    }

    .alert-video{
        width: 100%;
    }

    .el-message-box{
        width: 90%!important;
    }
    .el-message-box__content{
        padding: 0 0;
        /*width: 100%;*/
    }

}

</style>


<style>
    .el-message-box{
        background-color: transparent;
        border: none;
        box-shadow: 0 0 0 0 rgb(0 0 0);
        /*height: 80%;*/
        text-align: center;
    }
    .el-message-box__content{
        /*width: 100%;*/
        /*height: 100%;*/
        /*display: block;*/

        display: flex;
        justify-content: center;
        align-items: center;
    }


    .el-overlay {
        background-color: rgba(0,0,0,0.7);
    }

    .el-message-box__btns{
        display: none;
    }


</style>
