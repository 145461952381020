<template>
    <!--    <content-title link_field="首页"></content-title>-->
    <!--    <h1>{{ one_index }}</h1> {{ testField }} <p>{{ a }}——</p><p>{{ b }}——</p><p>{{ c }}</p>-->


    <div class="main-content-box" style="background-color: #fafafa;padding: 35px 40px;">
        <home-carousel></home-carousel>
    </div>

    <div class="main-content-box" style="background-color: #ffffff;padding: 15px 40px;">
        <div class="home-article-box">
            <div class="skills-content-box" style="overflow: hidden;">
                <div style="text-align: center;padding: 20px 0;">
                    <span style="font-size: 18px;color: #000000;">技术文章</span>
                </div>

                <div class="skills-list-box">
                    <div v-for="(item, index) in skillsArticleList" class="skills-article-box wow slideInUp" :data-wow-delay="(index * 0.08) + 's'">

                        <div :style="'background: url(' + item.img_src + ') no-repeat;background-size: 100%;'" class="sub-skills-article-box">

                            <div class="cover-box"></div>
                            <div class="skills-text-box">
                                <a :href="'/article/' + item.article_id" target="_blank"><div class="skills-text-title"><span>{{ item.title }}</span></div></a>
                                <div class="skills-text-time"><span>{{ item.public_time }}</span></div>
                                <div class="skills-text-content"><p>{{ item.content }}</p></div>
                            </div>


                            <a :href="'/article/' + item.article_id" target="_blank"><div style="position: absolute;bottom: 0;width: 100%;height: 90px;background-color: #fafafa;z-index: 555;" class="skills-btn-box">
                                <div style="position: absolute;left: 0;overflow: hidden;width: 50%;padding-left: 15px;">
                                    <div style="width: 100%;border-bottom: 1px solid black;z-index: 777;" class="test1"></div>
                                </div>

                                <div style="position: absolute;right: 0;overflow: hidden;width: 50%;padding-right: 15px;">
                                    <div style="width: 100%;border-bottom: 1px solid black;z-index: 777;" class="test2"></div>
                                </div>

                                <div style="width: 100%;z-index: 775;height: 1px;background-color: white;"></div>


                                <div style="height: 100%;padding: 0 15px;display: flex;align-items: center;overflow: hidden;justify-content: space-between;">
                                    <div><span>阅读全文</span></div>
                                    <div class="el-icon-arrow-right skills-read-btn" style=""></div>
                                </div>

                            </div></a>
                        </div>
                    </div>
                </div>

            </div>


            <div class="life-content-box">

                <div class="life-nav-box wow slideInUp">
                    <div class="life-nav-title"><span>生活</span></div>

                    <div class="life-nav-link">
                        <ul style="display: flex;" >
                            <li v-for="(item, index) in options" :style="{color: life_red_class===item.type_id? '#e74c3c':''}" @click="change_life(item.type_id)">{{ item.type_name }}</li>
                        </ul>
                    </div>

                    <div class="life-nav-select">
                        <el-select v-model="life_red_class" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.type_id"
                                :label="item.type_name"
                                :value="item.type_id">
                            </el-option>
                        </el-select>
                    </div>
                </div>


                <div class="life-article-box" >
                    <div class="life-article-list wow slideInLeft" data-wow-duration="0.8s">
                        <div class="wow slideInUp" v-if="!lifeArticleList.length" style="width: 100%;min-height: 150px;font-size: 20px;color: #C4C4C4;display: flex;justify-content: center;align-items: center;flex-grow: 1;">没有数据</div>


                        <div v-for="(item, index) in lifeArticleList" class="life-article-obj" :style="{float: !index? 'left': 'right', left: !index? '0': '20px'}">
                            <div class="life-yinying"></div>

                            <div style="padding: 30px 30px;position: relative;z-index: 2021;">
                                <a :href="'/article/' + item.article_id" target="_blank"><div v-if="!index" style="width: 100%;">
                                  <img :src="$imgUrl + item.img_src" alt="" style="width: 100%;">
                                </div></a>
                                <div class="life-text-box" :style="{paddingTop: !index? '20px':'0'}">


                                    <div style="display: table-cell;">
                                        <div style="width: 75px;color: #cccccc;">
                                            <span style="border-bottom: 1px solid #cccccc;padding-bottom: 6px;font-size: 16px;">{{ item.article_time.slice(0, 4) }}</span>
                                            <span style="display: block;padding-top: 10px;font-size: 15px;">{{ item.article_time.slice(11, 16) }}</span>
                                        </div>

                                    </div>

                                    <div style="display: table-cell">
                                        <div class="life-text-title text-overflow-two"><span><a :href="'/article/' + item.article_id" target="_blank">{{ item.title }}</a></span></div>
                                        <div class="life-text-content text-overflow-two"><p>{{ item.content.replace(/(^\s*)|(\s*$)/g, "") }}</p></div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div style="clear: both;"></div>
                    </div>
                  <div class="life-right-img wow slideInRight"  data-wow-duration="0.8s">
                    <img style="width: 100%;" :src='imgData.imgTwo' alt="">
                  </div>

                </div>
            </div>

            <a class="wow slideInUp" target="_blank" href="http://gg.kuwan8.com/union/kdysj_01/index/1_836" style="padding: 15px 0 30px 0;display: block;">
                <div class="advertisement-img" style="width: 100%;max-height: 300px;overflow: hidden;">
                    <img style="width: 100%;" :src='imgData.imgOne' alt="">
                </div>
            </a>

            <div class="new-content-box">
                <div style="display: flex;justify-content: flex-start;font-size: 18px;color: #000000;">
                    <div><span>最新发布文章</span></div>
                </div>

                <div>
                    <ArticleListTwo></ArticleListTwo>
                </div>

            </div>


        </div>

    </div>



</template>

<script>

import ContentTitle from "../components/public/ContentTitle";
import HomeCarousel from "../components/home/HomeCarousel";
import {ElMessage} from "element-plus";
import ArticleNav from "../components/public/ArticleNav";
import ArticleListTwo from "../components/article/ArticleListTwo";
import {WOW} from 'wowjs'

export default {
    name: 'Home',
    components: {
        ContentTitle,
        HomeCarousel,  // 首页轮播图
        ArticleNav,
        ArticleListTwo,

    },
    data() {
        return {
            skillsArticleList: [],
            lifeArticleList: [],
            options: [],
            life_red_class: [],
            imgData: {
              imgOne: '', // 广告图片
              imgTwo: '', // 广告图片
            },
        }
    },

    mounted(){
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: false,
            live: false,
        });
        wow.init();
    },

    created() {
        this.initImgData()
        let that = this;
        that.axios.get('/article_type?article_class=16').
        then(function (response) {
            that.options = response.data.data_list;
            that.life_red_class = response.data.data_list[0].type_id;

        }).catch(function (error) {
            ElMessage({
                showClose: true,
                message: '生活文章分类获取失败，请刷新',
                type: 'error'
            })
        });




        this.skillsArticleList = [
            {
                title: '码农翻身-我是一个线程',
                public_time: '2020-06-10',
                content: '我是一个线程，我一出生就被编了个号：0x3704，然后被领到一个昏暗的屋子里，在这里我发现了很多和我一模一样的同伴。我身边的...',
                img_src: `${this.$imgUrl}/home/7a54a0bb-eba5-4989-ae58-5ff9f15232ef.jpg`,
                article_id: 30,
            },
            {
                title: '计算机系统的计算思维',
                public_time: '2020-06-09',
                content: '教育部高等学校计算机基础课程教学指导委员会在《高等学校计算机基础教学发展战略研究报告暨计算机基础课程教学基本要求》中给出了计算机基础课程教学的4个教学模块，即①系统平台与计算环境，包括信息与社会、计算机系统...',
                img_src: `${this.$imgUrl}/home/ae618b05-543f-4530-8bf0-3d359d4bc13e.jpg`,
                article_id: 40,
            },
            {
                title: '谈计算机数据库信息管理应用',
                public_time: '2020-06-05',
                content: '数据库本身是计算机较为重要的应用领域，数据库出现以来在社会生产生活的各个方面，都为人们带来了极大的便利，互联网时代背景又是信息化时代，所以从实践中...',
                img_src: `${this.$imgUrl}/home/7dddabb3-ec4a-4cbf-a4ee-32feb0ffe4f5.jpg`,
                article_id: 41,
            },
        ];


    },
    methods: {
        initImgData() {
          this.imgData.imgOne = `${this.$imgUrl}/home/20211029135743a7bp0eoeuo.jpg`
          this.imgData.imgTwo = `${this.$imgUrl}/home/1637200973.png`

        },


        change_life: function f(type_id) {
            this.life_red_class = type_id;
        },

        test1: function (type_id) {
            console.log(this.life_red_class === type_id)
        },

        getLifeData: function (type_id) {
            let that = this;
            that.axios.get('/article_list?article_type=' + type_id)
                .then(function (response) {
                    that.lifeArticleList = response.data.data_list.splice(0, 4);
                    that.lifeArticleList[0].img_src = `/home/life.jpg`
                })
                .catch(function (error) {

                })
        }
    },

    watch: {
        life_red_class: function (val) {
            this.getLifeData(val);
        }
    }
}


</script>


<style>
.home-article-box {
    max-width: 1250px;
    margin: 0 auto;
}
</style>
<!--技术文章-->
<style>

    .skills-list-box{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .skills-article-box{
        flex-basis: 33.333333%;
        /*border: 1px solid red;*/
        box-sizing: border-box;
        min-height: 300px;

        padding: 15px;
    }
    .sub-skills-article-box{
        position: relative;
        height: 100%;
        width: 100%;
    }

    .cover-box{
        position: absolute;
        background-color: #fafafa;
        width: 100%;
        height: 100%;
    }

    .skills-text-box{
        position: absolute;
        padding: 20px 15px 90px 15px;
    }
    .skills-text-title{
        font-size: 16px;
        color: #000000;
        line-height: 45px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .skills-text-time{
        padding-bottom: 15px;
        color: #C4C4C4;
    }
    .skills-text-content{
        line-height: 20px;
        color: #999999;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    .skills-read-btn{
        width: 35px;
        height: 35px;
        border: 1px solid black;
        text-align: center;
        line-height: 35px;
        font-size: 20px;
        transform: translateX(100px);
    }


    @media screen and (min-width: 0px) and (max-width: 990px) {
        .skills-article-box{
            min-height: 180px;
        }

        .skills-list-box{
            flex-direction: column;
        }
        .skills-btn-box{
            display: none;
        }

        .skills-text-box{
            padding: 0 0 25px 0;
            border-bottom: 1px solid #C4C4C4;
        }

    }




</style>

<!--技术文章的动画-->
<style>

@media screen and (min-width: 990px) and (max-width: 9999px) {
    .skills-article-box div{
        transition: all 500ms;
    }
    .skills-article-box:hover .cover-box{
        opacity: 0;
    }
    .skills-article-box:hover .skills-text-box{
        /*top: 10px;*/
        transform: translateY(10px);
    }
    .skills-article-box:hover .skills-text-title{
        color: #e74c3c;
    }
    .skills-article-box:hover .skills-text-time{
        color: #e74c3c;
    }
    .skills-article-box:hover .skills-text-content{
        color: #000000;
    }
    .skills-article-box:hover .test1{
        transform: translateX(100%);
        /*width: 0!important;*/
    }
    .skills-article-box:hover .test2{
        width: 0!important;
    }
    .skills-article-box:hover .skills-read-btn{
        transform: translateX(0);
    }

    .skills-btn-box:hover{
        cursor: pointer;
    }
    .skills-text-title:hover{
        cursor: pointer;
    }

}



</style>

<!--生活文章页面-->
<style>
    .life-content-box{
        padding: 30px 0;
        /*border: 1px solid red;*/
    }

    .life-nav-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .life-nav-title{
        font-size: 18px;
    }

    .life-nav-link ul{
        display: flex;
    }

    .life-nav-link li{
        font-size: 15px;
        color: #595959;
        padding: 10px 0 10px 20px;
    }
    .life-nav-link li:hover{
        cursor: pointer;
        color: #e74c3c;
    }

    .life-nav-select{
        display: none;
    }

    .life-article-box{
        position: relative;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
    }

    .life-article-list{
        width: 75%;
    }
    .life-article-obj{
        background-color: #fafafa;
        margin-bottom: 20px;
        position: relative;
        width: 50%;
        z-index: 1;
    }

    .life-yinying{
        position: absolute;
        width: 0;
        height: 0;
        box-shadow:2px 2px 5px #C4C4C4;
        bottom: 0;
        transition: height 500ms;


    }
    .life-article-obj:hover .life-yinying{
        width: 100%;
        height: 100%;
    }


    .life-article-obj:hover .life-text-title a{
        color: #e74c3c;
    }



    .life-text-title{
        font-size: 14px;
        color: #333333;
        -webkit-line-clamp: 1;
        /*white-space: nowrap;*/
    }
    .life-text-title a:hover{
        cursor: pointer;
    }

    .life-text-content{
        color: #999999;
        line-height: 25px;
        -webkit-line-clamp: 2;
        padding-top: 8px;

    }


    .life-text-box{
        display: table;
    }

    .life-right-img{
        width: 22%;
        /*max-width: 22%;*/
        height: 100%;
        text-align: right;
    }
    .life-right-img img{
        width: 100%;
    }

    @media screen and (min-width: 991px) and (max-width: 9999px) {

        .life-text-box{
            height: 80px;
        }

    }

    @media screen and (min-width: 0px) and (max-width: 990px) {
        .life-nav-link{
            display: none;
        }
        .life-nav-select{
            display: block;
        }

        .life-article-obj{
            width: 100%;
            left: 0!important;
        }
        .life-article-list{
            width: 100%;
        }
        .life-right-img{
            display: none;
        }


    }


</style>

<!--生活文章模块的选择器-->
<style scoped>
/*.el-popper{*/
/*    top: 5560px!important;*/
/*}*/
</style>

<!--最新文章-->
<style>
    .new-content-box{
        padding: 30px 0;
    }
</style>
